import React from 'react';
import "../style/pages/Home/_trackOrders.scss";

export default function TrackOrders() {
    return (
        <div className="track-orders-container">
            <div className="coming-soon-text">Comming Soon</div>
        </div>
    );
}
